<template>
  <b-form-group
    v-if="form.values != null"
    :id="groupId"
    :label="def.label"
    :label-for="inputId"
    :description="def.description">

    <div
      v-if="def.type == 'readonly'"
      :id="inputId">
      {{ value }}
    </div>

    <b-form-input
      v-if="['text', 'email'].includes(def.type)"
      :id="inputId"
      v-model="value"
      :type="def.type"
      :required="def.required == true"
      :placeholder="def.placeholder"
    />

    <b-form-select
      v-if="def.type == 'select'"
      :id="inputId"
      v-model="value"
      :type="def.type"
      :options="def.options"
      :required="def.required == true"
      :placeholder="def.placeholder"
    />

    <b-form-checkbox
      v-if="def.type == 'checkbox'"
      :id="inputId"
      v-model="value"
      type="checkbox"
      :required="def.required == true"
      value="1"
      unchecked-value="0"
    />

    <c-select
      v-if="def.type == 'v-select'"
      v-model="selectValue"
      placeholder=" "
      :id="inputId"
      :options="def.options"
      :required="def.required == true"
    />

  </b-form-group>
</template>

<script>

  export default {
    name: 'FormField',
    components: {
    },
    data () {
      return {
      }
    },
    props: {
      form: Object,
      name: String,
    },
    computed: {
      groupId () { return this.name + '-group'; },
      inputId () { return this.name + '-input'; },
      def () { return this.form.fields[this.name] },
      selectValue: {
        get () {
          let value = this.form.values[this.name]
          return value
        },
        set(val) {
          if (val.target)
            val = val.target.value
          this.$set(this.form.values, this.name, val)
          this.$emit('change')      
        }
      },
      value: {
        get () { 
          let value = this.form.values[this.name]
          return value
        },
        set (val) {
          if (this.form.values[this.name] == val) return
          this.$set(this.form.values, this.name, val)
          this.$emit('change')
         },
      },
      vSelectRequired: function () {
        return this.value == undefined || this.value == ''
      }
    },
    mounted () {
    }
  }
</script>

<style lang="scss" scoped>

</style>